.aluno {
  width: 100%;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 370px 1fr;
  gap: 0;
  padding-top:95px;
}

.leftBar {
  height: calc(100vh - 100px);
  background-color: var(--backsilver-color);
}

.userPhotoArea {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 20px;
  background-color: black;
}

.image {
  width: 175px;
  height: 175px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 1px solid var(--second-color);
  border-radius: 50%;
}

.dados{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding:20px;
}

.pontos {
  display: flex;
  font-size: 1.2rem;
  max-width: 100px;
  text-align: center;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--second-color);
}

.pontos::before {
  margin-right: 5px;
  content:' ';
  display: block;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  background-image: url('../../../Assets/icon_star.svg');  
}

.nome {
  width: 100%;
  color: white;
  text-align: center;
 margin-top:7px;
  font-weight: bold;
  font-size: 1.1rem;
}

.idade {
  width: 100%;
  color: white;
  text-align: center;
  margin-top:5px;
  font-size: 1.1rem;
}

.desde {
  width: 100%;
  color: white;
  text-align: center;
  margin-top:5px;
  font-size: 1.1rem;
}

.btSalvar {
  margin:20px 10px;
}

.icon:hover {
  filter: invert(54%) sepia(39%) saturate(6769%) hue-rotate(353deg) brightness(96%) contrast(92%);
}

.icon{
  cursor: pointer;
  filter: invert(49%) sepia(0%) saturate(1017%) hue-rotate(193deg) brightness(87%) contrast(86%);
}


.alunoMenu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.alunoMenu li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding:5px;
  font-size: 1.4rem;
}

.editarTreino::before {
  margin-right: 10px;
  content:' ';
  display: block;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  background-image: url('../../../Assets/icon_agenda.svg');  
}

.editarCadastro::before {
  margin-right: 10px;
  content:' ';
  display: block;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  background-image: url('../../../Assets/icon_user.svg');  
}

.stats::before {
  margin-right: 10px;
  content:' ';
  display: block;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  background-image: url('../../../Assets/icon_stats.svg');  
}

.content {
  width: 100%;
}