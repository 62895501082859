.graphic {
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.titulo {
  width: 100%;
  font-family: var(--type-second);
  margin:5px 10px;
  font-size: 1.4rem;
}

.content {
  width:100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var( --backsilver-color);
  border-radius: 5px;
  
}