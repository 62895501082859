.listagemAreaUsuarios {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding:25px 15px 15px 15px;
  gap: 1rem;
}

.manageArea > div {
  all: inherit;
}

.noData {
  margin:25px;
  font-family: var(--type-second);
}

.itemUser {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 4fr 1fr;
  gap: 1rem;
  background-color: #ededed;
  border-radius: 5px;
  margin:3px;
  padding:10px 20px;
  align-items: center;
}


.itemUser p {
  font-size: 1rem;
}

.itemUser img {
  border-radius: 50%;
}

.bts {
  display: flex;
  width:100px;
  flex-wrap: wrap;
}

.removeUser {
  cursor: pointer;
  display:flex;
  width: 25px;
  height: 25px;
  margin:5px 8px;
  background-image: url('./../../../Assets/icon_delete.svg');
}

.editUser {
  cursor: pointer;
  display:flex;
  width: 25px;
  height: 25px;
  margin:5px 8px;
  background-image: url('./../../../Assets/icon_edit.svg');
}

.emailUser {
  cursor: pointer;
  display:flex;
  width: 25px;
  height: 25px;
  margin:5px 8px;
  background-image: url('./../../../Assets/icon_sendmail.svg');
}

.whatsUser {
  cursor: pointer;
  display:flex;
  width: 25px;
  height: 25px;
  margin:5px 8px;
  background-image: url('./../../../Assets/icon_whatsapp.svg');
}

.icon:hover {
  filter: invert(54%) sepia(39%) saturate(6769%) hue-rotate(353deg) brightness(96%) contrast(92%);
}

.icon{
  filter: invert(49%) sepia(0%) saturate(1017%) hue-rotate(193deg) brightness(87%) contrast(86%);
}

