.box {
  background-color: var( --backsilver-color);
  height:100px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding:15px;
  margin: 10px 0;
}

.icon {
  width: 50px;
  height: 50px;
  margin: 20px 10px 10px 10px;
}

.infos {
 width: 200px;
 display: flex;
 flex-wrap: wrap;
 justify-content: flex-end;
}

.titulo {
  width: 100%;
  text-align: right;
  color: var(--second-color);
}

.valor {
  font-family: var(--type-second);
  font-size: 1.8rem;
}

.money {
  background-image: url('./../../../Assets/icon_money.svg');
  background-repeat: no-repeat;
  background-size: auto;
}

.users {
  background-image: url('./../../../Assets/icon_users.svg');
  background-repeat: no-repeat;
  background-size: auto;
}

.instrutor {
  background-image: url('./../../../Assets/icon_instrutor.svg');
  background-repeat: no-repeat;
  background-size: auto;
}

.group {
  background-image: url('./../../../Assets/icon_group.svg');
  background-repeat: no-repeat;
  background-size: auto;
}