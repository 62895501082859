.weeks{
  display: block;
  width: 100%;
}

.title{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin:20px 0;
}

.title h1{
  font-family: var(--type-second);
  font-size: 2rem;
}

.videosContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.videoItem{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: auto;
  width: 320px;
  height: auto;
  margin:15px;
  background-color: var(--backsilver-color);
  border-radius: 5px;
  font-size: 1rem;
  padding:5px;
}

.videoPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 160px;
  overflow: hidden;
  background-size: 130%;
  background-position: center center;
  background-color: black;
}

.videoDesc {
  display: flex;
  width: 100%;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}

.modal {
  background-color: rgba(0,0,0,.9);
  display:flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width:100%;
  height: 100%;
  left: 0;
  top:0;
  z-index: 999;
}

.modalContent {
  display: flex;
}

.atributos {
  padding: 20px;
  color:white;
  margin:15px;
  border-radius: 5px;
  border: 1px solid var(--second-color);
}

.atributos h1{
  font-size: 2rem;
}

.atributos h2{
  font-size: 1.5rem;
}

.close {
  position: absolute;
  top:15px;
  right: 15px;
  background-image: url('../../../Assets/icon_close.svg');
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.icon{
  cursor: pointer;
  filter: invert(49%) sepia(0%) saturate(1017%) hue-rotate(193deg) brightness(87%) contrast(86%);
}

.icon:hover {
  filter: invert(54%) sepia(39%) saturate(6769%) hue-rotate(353deg) brightness(96%) contrast(92%);
}

.cron {
    background-image: url('../../../Assets/icon_timer.svg');
    width: 48px;
    height: 48px;
}

.openAtributos {
  background-image: url('../../../Assets/icon_bullets.svg');
  width: 35px;
  height: 35px;
}

.back {
  background-image: url('../../../Assets/icon_voltar.svg');
  width: 35px;
  height: 35px;
}

.done {
  width: 100%;
  margin: 10px 5px;
  display: flex;
  justify-content: center;
  padding:20px;
  border-radius: 5px;
  background-color: var(--second-color);
  color:white;
  font-size: 1.2rem;
}

@media (max-width: 500px) {
  .done {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    padding:20px;
    border-radius: 5px;
    background-color: var(--second-color);
    color:white;
    font-size: 1.2rem;
    text-align: center;
  }
}

.doneNull {
  width: 100%;
  margin: 10px 5px;
  display: flex;
  justify-content: center;
  padding:20px;
  border-radius: 5px;
  background-color: var(--backdark-color);
  color:white;
  font-size: 1.2rem;
}

.grupo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--backdark-color);
  padding: 5px;
  border-radius: 5px;
}

@media (max-width: 500px) {
  .doneNull {
    width: 100%;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    padding:20px;
    border-radius: 5px;
    background-color: var(--backdark-color);
    color:white;
    font-size: 1.2rem;
    text-align: center;
  }

  .grupo {
    width: 100%;
    display: block;
    background-color: var(--backdark-color);
    padding: 5px;
    border-radius: 5px;
  }
}

.grupo h3{
  width: 100%;
  text-align: left;
  color:white;
  margin: 5px 0 0 20px;
}

.grupo p{
  width: 100%;
  text-align: left;
  color:white;
}

.grupo > p{
  margin: 5px 0 0 20px;
  font-weight: bold;
}



.grupo .videoItem {
  background-color: var(--backdark-color);
}