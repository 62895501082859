.listarTreinos {
  width: 100%;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 370px 1fr;
  gap: 0;
  padding-top:95px;
}

.leftBar {
  padding:25px;
  height: calc(100vh - 100px);
  /*background-color: var(--backsilver-color);*/
  background-image: url('./../../../Assets/back_sidebar1.jpg');
  background-repeat: no-repeat;
}

.title {
  font-family: var(--type-second);
  font-size: 2rem;
  margin:10px 0;
  color: white;
  margin-bottom: 25px;
}

.btSalvar {
  margin:20px 10px;
}

.form {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap:.3rem;
  align-items: center;
}

.form input{
  font-family: var(--type-first);
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    font-size: 1.1em;
    font-weight: 300;
    margin: 5px 5px;
    height: 40px;
    background-color: var( --backsilver-color);
}

.form button{
  font-size: 1rem;
  font-family: var(--type-second);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background-color: black;
  color: white;
  height: 40px;
  box-sizing: border-box;
  transition: .1s;
}

.form input:focus, .form input:hover {
  outline: none;
  border-color: var(--second-color);
  background-color: white;
  box-shadow: 0 0 0 3px #ffc8b0;
}

.form button:hover,
.form button:focus {
  outline:none;
  box-shadow: 0 0 0 3px var(--second-color), 0 0 0 4px black;
}