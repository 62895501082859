.addTreino {
  width: 100%;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 370px 1fr;
  gap: 0;
  padding-top:95px;
}

.leftBar {
  padding:25px;
  height: calc(100vh - 100px);
  /*background-color: var(--backsilver-color);*/
  background-image: url('./../../../Assets/back_sidebar0.jpg');
  background-repeat: no-repeat;
}

.title {
  font-family: var(--type-second);
  font-size: 1.6rem;
  margin:10px 0;
}

.btSalvar {
  margin:20px 10px;
}

.modal {
  background-color: rgba(0,0,0,.9);
  display:flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width:100%;
  height: 100%;
  left: 0;
  top:0;
  z-index: 999;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalContent h1{
  font-size: 2rem;
  color:white;
  margin-bottom: 25px;
  font-family: var(--type-second);
}

.modalContent span{
  margin:8px 0;
}

.close {
  position: absolute;
  top:15px;
  right: 15px;
  background-image: url('../../../Assets/icon_close.svg');
  width: 48px;
  height: 48px;
  cursor: pointer;
}