.home {
  width: 100%;
  padding-top:95px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: 320px;
  background-image: url('./../../../Assets/banner_home.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.boxes {
  width: 100%;
  max-width: 60rem;
  margin: 25px auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3,1fr);
}

.boxes2 {
  width: 100%;
  max-width: 650px;
  margin: 25px auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.subBoxes {
  width: 100%;
  margin: 25px;
  
}

.graphics {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  display: grid;
  width: 100%;
  flex-wrap: wrap;
}

.banner2 {
  width:500px;
  height: 500px;
  background-image: url('./../../../Assets/banner_instrutor_replace.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 20px 0;
}

.banner_video {

}